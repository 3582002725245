var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { align: "center" } },
    [
      _c("br"),
      _vm._m(0),
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "justify-center row" },
        [
          _c(
            "div",
            { staticClass: "col-12 col-md-2" },
            [
              _c("q-select", {
                attrs: {
                  outlined: "",
                  options: _vm.options,
                  label: "Anno di produzione",
                },
                model: {
                  value: _vm.anno,
                  callback: function ($$v) {
                    _vm.anno = $$v
                  },
                  expression: "anno",
                },
              }),
            ],
            1
          ),
          _c("q-btn", {
            attrs: { color: "deep-orange", label: "Cerca" },
            nativeOn: {
              click: function ($event) {
                return _vm.onEstraiDati.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c("br"),
      _c(
        "q-card",
        { attrs: { flat: "" } },
        [
          _c(
            "q-tabs",
            {
              attrs: { align: "center", "narrow-indicator": "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("q-tab", {
                staticClass: "text-weight-bolder",
                attrs: { name: "preventivi", label: "Preventivi" },
              }),
              _c("q-tab", {
                staticClass: "text-weight-bolder",
                attrs: { name: "pratiche", label: "Pratiche" },
              }),
            ],
            1
          ),
          _c(
            "q-tab-panels",
            {
              ref: "tabs",
              attrs: {
                animated: "",
                "transition-prev": "jump-down",
                "transition-next": "jump-down",
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("q-tab-panel", { attrs: { name: "preventivi" } }, [
                _c(
                  "div",
                  {
                    staticClass: "row justify-center",
                    staticStyle: { "background-color": "#fff" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-4", attrs: { align: "left" } },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "q-mb-sm",
                            staticStyle: { color: "#000" },
                          },
                          [_vm._v("Stato dei preventivi per Area")]
                        ),
                        _c("QQBarChart", {
                          attrs: { chartData: _vm.dati_preventivi },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-md-3 q-pl-md",
                        attrs: { align: "left" },
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "q-mb-sm",
                            staticStyle: { color: "#000" },
                          },
                          [_vm._v("Percentuale stato dei preventivi")]
                        ),
                        _c("QQPieChart", {
                          attrs: { chartData: _vm.dati_preventivi_pie },
                        }),
                        _c("br"),
                        _c(
                          "table",
                          {
                            staticStyle: { color: "#000" },
                            attrs: { width: "100%" },
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _c("strong", [
                                    _vm._v("Totale preventivi analizzati"),
                                  ]),
                                  _vm._v(":"),
                                ]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(_vm._s(_vm.totale_preventivi)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { attrs: { colspan: "2" } }, [
                                  _c("hr"),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Preventivi attivi:")]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(_vm._s(_vm.attivi_percentuale) + " %"),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Preventivi annullati:")]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(
                                    _vm._s(_vm.annullati_percentuale) + " %"
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Preventivi scaduti:")]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(
                                    _vm._s(_vm.scaduti_percentuale) + " %"
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [
                                  _vm._v("Preventivi convertiti in Pratica:"),
                                ]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trasformati_percentuale) + " %"
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                        _c("br"),
                        _c("br"),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("q-tab-panel", { attrs: { name: "pratiche" } }, [
                _c("div", { staticClass: "row justify-center" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-12" },
                    [
                      _c(
                        "q-list",
                        { attrs: { bordered: "", separator: "" } },
                        [
                          _c(
                            "q-item",
                            [
                              _c(
                                "q-item-section",
                                [
                                  _c("q-item-label", [
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "22px" } },
                                      [_vm._v("SITUAZIONE PRATICHE")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "div",
                                        { attrs: { align: "center" } },
                                        [_vm._v("DEFINITE")]
                                      ),
                                    ]
                                  ),
                                  _c("q-item-label", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "24px",
                                          "text-align": "center",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getTotalePraticheDefinite()
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "q-item-section",
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "div",
                                        { attrs: { align: "center" } },
                                        [_vm._v("TOTALE")]
                                      ),
                                    ]
                                  ),
                                  _c("q-item-label", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "24px",
                                          "text-align": "center",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.getTotalePratiche()))]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c("br"),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-md-4",
                      attrs: { align: "left" },
                    },
                    [
                      _c("h5", { staticClass: "q-mb-sm" }, [
                        _vm._v("Stato delle pratiche"),
                      ]),
                      _c(
                        "q-list",
                        { attrs: { bordered: "", separator: "" } },
                        _vm._l(
                          _vm.dati_pratiche.composizionePratiche,
                          function (pratica, index) {
                            return _c(
                              "q-item",
                              { key: index },
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", [
                                      _vm._v(_vm._s(pratica.attivita)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  [
                                    _c("q-item-label", [
                                      _c(
                                        "table",
                                        { attrs: { width: "100%" } },
                                        [
                                          _c("tbody", [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "In attesa di lavorazione:"
                                                ),
                                              ]),
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      pratica.attesa_lavorazione
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v("In lavorazione:"),
                                              ]),
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(pratica.lavorazione)
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c("td", [_vm._v("Annullate:")]),
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(pratica.annullate)
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "In attesa intervento Ufficio:"
                                                ),
                                              ]),
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      pratica.attesaufficio
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "In attesa intervento BackOffice:"
                                                ),
                                              ]),
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      pratica.attesabackoffice
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c("td", [_vm._v("Definite:")]),
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(pratica.definite)
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "2" } },
                                                [_c("hr")]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v("Totale:"),
                                                ]),
                                              ]),
                                              _c("td", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(pratica.totale)
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-4 q-pl-md q-ml-md",
                      staticStyle: { "background-color": "#fff" },
                      attrs: { align: "left" },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "q-mb-sm",
                          staticStyle: { color: "#000" },
                        },
                        [_vm._v("Questi dati sono falsi")]
                      ),
                      _c("QQHorizBarChart", {
                        attrs: { chartData: _vm.horizChartData },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Informazioni sul portafoglio"),
      ]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }