import { render, staticRenderFns } from "./MenuStatistiche.vue?vue&type=template&id=113516f8&"
import script from "./MenuStatistiche.vue?vue&type=script&lang=js&"
export * from "./MenuStatistiche.vue?vue&type=script&lang=js&"
import style0 from "./MenuStatistiche.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QSelect,QBtn,QCard,QTabs,QTab,QTabPanels,QTabPanel,QList,QItem,QItemSection,QItemLabel});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('113516f8')) {
      api.createRecord('113516f8', component.options)
    } else {
      api.reload('113516f8', component.options)
    }
    module.hot.accept("./MenuStatistiche.vue?vue&type=template&id=113516f8&", function () {
      api.rerender('113516f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Statistiche/MenuStatistiche.vue"
export default component.exports